import axios from 'axios';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import Cookies from 'js-cookie';

import { getBearerToken } from './getBearerToken';
import { getMobileHostname } from './getMobileHostname';
import { isNativeMobile } from './isNativeMobile';

export const cleanUpStaleCookies = () => {
  const attributes = {
    domain: `.${window.location.host.split('.').slice(-2).join('.')}`,
    path: '/'
  };
  Cookies.remove('isLoginDisclosure', attributes);
  Cookies.remove('token', attributes);
  Cookies.remove('tokenExpiration', attributes);
  Cookies.remove('refreshToken', attributes);
};

export const logout = async (timeout?) => {
  const isNative = await isNativeMobile();
  const token = await getBearerToken(isNative);
  cleanUpStaleCookies();

  try {
    // token could be null and if it is, there is nothing to log out so skip the call
    if (token) {
      await axios.get('/auth/api/v2/logout', {
        headers: {
          Authorization: token
        }
      });
    }
    if (!isNative) {
      window.location.href = `${window.location.origin}${timeout === true ? `?error=TIMEOUT` : ''}`;
    } else {
      await SecureStoragePlugin.remove({ key: 'token' }).catch(() =>
        console.warn('token unavailable')
      );
      await SecureStoragePlugin.remove({ key: 'tokenExpiration' }).catch(() =>
        console.warn('token expiration unavailable')
      );
      const mobileHost = await getMobileHostname();
      window.location.assign(
        `${window.location.protocol}//${mobileHost}/?logout=true${timeout === true ? `&error=TIMEOUT` : ''}`
      );
    }
  } catch (e) {
    console.error('Failed to logout', e);
    window.location.href = window.location.origin;
  }
};
